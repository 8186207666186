import { Layout, Typography, Space } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function Home() {
  return (
    <Content style={{ padding: "0 24px", minHeight: 280 }}>
      <Space>
        <Title level={3}>Bienvenido</Title>
      </Space>
    </Content>
  );
}

export default Home;
