import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Menu, Dropdown, Typography } from "antd";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { useAuth } from "../contexts/AuthContext";
import { pages } from "../constants/menu";

function MenuBar({ setError }) {
  const location = useLocation();
  const history = useHistory();
  const { logout, currentUser } = useAuth();

  const isCurrentUserAdmin = () => {
    return (
      currentUser?.reloadUserInfo?.customAttributes?.length > 0 &&
      JSON.parse(currentUser.reloadUserInfo.customAttributes).ROLE_ADMIN
    );
  };
  const currentKey = String(
    pages.find((_item) => location.pathname === _item.path)?.key ?? "1"
  );

  const [selectedKey, setSelectedKey] = useState(currentKey);

  useEffect(() => {
    const currentKeyEf = String(
      pages.find((_item) => location.pathname === _item.path)?.key ?? "1"
    );
    setSelectedKey(currentKeyEf);
  }, [location]);

  const onClickMenu = (item, ev) => {
    if (item.key === "-1") {
      return;
    } else {
      const clicked = pages.find((_item) => _item.key === item.key);
      history.push(clicked.path);
    }
  };

  const handleDropdownClick = (item) => {
    if (item.key === "setting:1" || item.key === "setting:name") {
      history.push("/update-profile");
    } else if (item.key === "setting:2") {
      handleLogout();
    }
  };

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  const { Text } = Typography;
  const optionsMenu = (
    <Menu onClick={handleDropdownClick}>
      <Menu.Item key="setting:name">
        <Text code>{currentUser && currentUser.email}</Text>
      </Menu.Item>
      <Menu.Item key="setting:1" style={{ textAlign: "right" }}>
        Perfil
      </Menu.Item>
      <Menu.Item key="setting:2" style={{ textAlign: "right" }}>
        Salir
      </Menu.Item>
    </Menu>
  );

  return (
    (currentUser && (
      <>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[selectedKey]}
        onClick={onClickMenu}
      >

        {pages.map((item) =>
          item.showInMenu &&
          (isCurrentUserAdmin() === item.admin || !item.admin) ? (
            <Menu.Item key={item.key} style={{height: 85, paddingTop: 10}} >{item.label}</Menu.Item>
          ) : null
        )}
        <Menu.Item
          key="-1"
          style={{ float: "right", textAlign: "right", marginLeft: "auto", height: 85, paddingTop: 10 }}
        >
          <Dropdown overlay={optionsMenu}>
            <span>
              <SettingOutlined /> <DownOutlined />
            </span>
          </Dropdown>
        </Menu.Item>
      </Menu>
      </>
    )) ?? null
  );
}

export default MenuBar;
