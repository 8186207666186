// React
import React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";

// Antd
import { Table, Space, notification, Button, Input, Row, Form, Modal, DatePicker, TimePicker, InputNumber } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// Components
import FilterControls from "./FilterControls";

// Services
import SmsService from "../services/smsservice";

// Plugins
import Highlighter from "react-highlight-words";

// Dates
import moment from "moment";

function Report() {
  const smsService = useMemo(() => new SmsService(), []);
  const [dataSource, setdataSource] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterTextFactura, setFilterTextFactura] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [originalDatasource, setOriginalDatasource] = useState(0);

  const [currentSms, setCurrentSms] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  
  const { TextArea } = Input;

  const getReportData = useCallback(() => {
    smsService
      .getAllSms(page, size, dateStart, dateEnd, filterText, (filterTextFactura ?? ""))
      .then((response) => {
        const { content, totalElements } = response.data;
        setTotalRows(totalElements);
        setdataSource(content);
        setOriginalDatasource(content);
      });
  }, [smsService, filterText, filterTextFactura, page, size, dateStart, dateEnd]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  let searchInput = undefined;

  const onChangePage = (p, s) => {
    setSize(s);
    setPage(p - 1);
  };

  const onChangeDate = (dateStr, dp) => {
    if (dp === "start") {
      setDateStart(dateStr);
    } else {
      setDateEnd(dateStr);
    }
  };

  const getCallback = () => {
    if (dateStart.length === 0 || dateEnd.length === 0) {
      notification["error"]({
        message: "Error",
        description: "Las fechas no son correctas",
      });
      return;
    }
    getReportData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              if(dataIndex==='factura'){
                setFilterTextFactura(selectedKeys[0]);
              }else{
                setFilterText(selectedKeys[0]);
              }
              setSearchColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText, filterText, filterTextFactura]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setdataSource(originalDatasource);
    setSearchText("");
    setFilterText("");
    setFilterTextFactura("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Fecha",
      dataIndex: "createdat",
      key: "createdat",
    },
    {
      title: "Mensaje",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      sorter: (a, b) => String(a.telefono).localeCompare(String(b.telefono)),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("telefono"),
    },
    { 
      title: "Confirmación",
      dataIndex: "confirmacion",
      key: "confirmacion",
    },
    {
      title: "Prefactura",
      dataIndex: "factura",
      key: "factura",
      sorter: (a, b) => String(a.factura).localeCompare(String(b.factura)),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("factura")
    },
  ];

  const onFinish = (values) => {
    const id = values.id ?? 0;
    console.log(id);
    values = {
        createdat: values.fecha.format("YYYY-MM-DD") + " " + values.hora.format("HH:mm:ss"),
        content: values.content,
        telefono: values.telefono,
        confirmacion: values.confirmacion,
        factura: values.factura
    };
    console.log('Success:', values);
    smsService.saveSms(id, values).then(r => {
      setIsModalVisible(false);
      if(id === 0){
        setdataSource([...dataSource, r.data]);
      }else{
        setdataSource(dataSource.map(e => {
          if(e.id === id){
            return {...values, id};
          }else{
            return e;
          }
        }));
      }
      notification.success({message: "Guardado", description: "El mensaje fue guardado con éxito."})
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickRow = (row) => {
    setCurrentSms({...row, fecha: moment(row.createdat.split(' ')[0]), hora: moment(row.createdat.split(' ')[1],'HH:mm:ss')});
    console.log({...row, fecha: row.createdat.split(' ')[0], hora: row.createdat.split(' ')[1]});
    setIsModalVisible(true);
  };

  return (
    <div className="App">
      <h2>Reporte de mensajes SMS</h2>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Space style={{marginBottom: 16}} size="large" direction="horizontal">
            <Button type="primary" onClick={() => setIsModalVisible(true)}>Agregar</Button>
            <FilterControls onChangeDate={onChangeDate} getCallback={getCallback} />
          </Space>
        </Row>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: size,
            onChange: onChangePage,
            total: totalRows,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: _ => {
                onClickRow(record);
              },
            };
          }}
        />
      </Space>
      <Modal title="Datos de SMS" visible={isModalVisible} footer={null} onCancel={()=>setIsModalVisible(false)} >
        <Form
        name="savesms"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={currentSms}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

      <Form.Item
          label="ID"
          name="id"
          style={{ display: 'none' }}
        >
          <Input maxLength={10} type="hidden" />
        </Form.Item>
        
        <Form.Item
          label="Teléfono"
          name="telefono"
          rules={[{ required: true, pattern: new RegExp("[0-9]{10}"), message: 'Introduce un teléfono válido' }]}
        >
          <Input maxLength={10} />
        </Form.Item>


        <Form.Item
          label="Fecha"
          name="fecha"
          rules={[{ required: true, message: 'Introduce una fecha' }]}
        >
          <DatePicker placeholder="Fecha" style={{width: "100%"}}/>
        </Form.Item>

        <Form.Item
          label="Hora"
          name="hora"
          rules={[{ required: true, message: 'Introduce una hora' }]}
        >
          <TimePicker placeholder="Hora" style={{width: "100%"}}  />
        </Form.Item>

        <Form.Item
          label="Mensaje"
          name="content"
          rules={[{ required: true, min: 5, max: 500, message: 'Introduce un mensaje válido'}]}
        >
          <TextArea rows={4} style={{width: "100%"}} maxLength={500} />
        </Form.Item>

        <Form.Item
          label="Confirmación"
          name="confirmacion"
          rules={[{ required: true, min: 100000, max: 999999, message: 'Introduce un número de confirmación válido', type: 'number'}]}
        >
          <InputNumber style={{width: "100%"}} maxLength={6} />
        </Form.Item>

        <Form.Item
          label="Factura"
          name="factura"
          rules={[{ required: true, min: 1, max: 999999, message: 'Introduce un número de factura válido', type: 'number'}]}
        >
          <InputNumber style={{width: "100%"}} maxLength={6} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
      </Modal>
    </div>
  );
}

export default Report;
