import { React, useState } from "react";

import "./App.scss";
//import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Report from "./components/Report";
import ReportCompras from "./components/ReportCompras";
import Users from "./components/Users";
import { Layout, Alert, Switch } from "antd";

import Home from "./components/Home";
import Signup from "./components/Signup";
import { AuthProvider } from "./contexts/AuthContext";
import {
  HashRouter as Router,
  Switch as RouteSwitch,
  Route,
} from "react-router-dom";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./components/ForgotPassword";
import UpdateProfile from "./components/UpdateProfile";
import MenuBar from "./components/MenuBar";
import UserInfo from "./components/UserInfo";
import BreadcrumBar from "./components/BreadcrumBar";
import { useThemeSwitcher } from "react-css-theme-switcher";
import ReporteFacturas from "./components/ReporteFacturas";

const { Header, Content, Footer } = Layout;

function App() {
  const [error, setError] = useState("");
  const hideHeaders = { display: "none" };
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState();

  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  if (status === "loading") {
    return null;
  }

  return (
    <AuthProvider>
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Layout className="layout">
            <div
              style={
                window.location.href.indexOf("ventas-list") !== -1
                  ? hideHeaders
                  : null
              }
            >
              <Header style={{ height: 85 }}>
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(https://sms.promolatina.mx/logo.jpg)`,
                  }}
                />
                <MenuBar setError={setError} />
              </Header>
            </div>
            <div
              className="headcontrols"
              style={
                window.location.href.indexOf("ventas-list") !== -1
                  ? hideHeaders
                  : null
              }
            >
              <span>Tema: {currentTheme}</span>
              <br />
              <Switch checked={isDarkMode} onChange={toggleTheme} />
              <div style={{ paddingBottom: "10px", marginTop: "20px" }}>
                <UserInfo />
              </div>
              {error && <Alert type="error">{error}</Alert>}
              <BreadcrumBar />
            </div>
            <Content style={{ padding: "0 50px" }}>
              <div className="site-layout-content">
                <RouteSwitch>
                  <PrivateRoute exact path="/" component={Home} />
                  <PrivateRoute
                    exact
                    path="/reporte-ventas"
                    component={Report}
                  />
                  <PrivateRoute
                    exact
                    path="/reporte-compras"
                    component={ReportCompras}
                  />
                  <PrivateRoute
                    exact
                    path="/reporte-facturas"
                    component={ReporteFacturas}
                  />
                  <Route exact path="/reporte-ventas-list" component={Report} />
                  <PrivateRoute
                    path="/update-profile"
                    component={UpdateProfile}
                  />
                  <PrivateRoute path="/users" component={Users} />
                  <Route path="/signup" component={Signup} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route component={Home} />
                </RouteSwitch>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: "center" }}>Sistema de reportes</Footer>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
