import React, { useContext, useState, useEffect, useRef } from "react";
import { auth } from "../firebase/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  sendEmailVerification
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const isMountedRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    localStorage.setItem("sendemail", "1");
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    localStorage.removeItem("token");
    setCurrentUser(undefined);
    return auth.signOut();
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmailHelper(email) {
    return updateEmail(currentUser, email);
  }

  function updatePasswordHelper(password) {
    return updatePassword(currentUser, password);
  }

  useEffect(() => {
    isMountedRef.current = true;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.stsTokenManager) {
        localStorage.setItem("token", user.stsTokenManager.accessToken);
      }

      if(user!=null && !user.emailVerified){
        try{
          if(localStorage.getItem("sendemail")!=null){
            sendEmailVerification(user);
            localStorage.removeItem("sendemail");
          }
        }catch{console.log('Error sending email verification');}
        logout();
      }

      if(isMountedRef.current && user!=null && user.emailVerified){
        setCurrentUser(user);
      }
      
      if(isMountedRef.current){
        setLoading(false);
      }
      
      if(user==null){
        localStorage.removeItem("token");
      }
    });

    return () => {isMountedRef.current = false; unsubscribe();};
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmailHelper,
    updatePasswordHelper,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
