// React
import React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";

// Antd
import { Table, Space, Row, Popconfirm, Button } from "antd";

// Services
import UserService from "../services/userservice";

function Users() {
  const userService = useMemo(() => new UserService(), []);
  const [dataSource, setdataSource] = useState([]);

  const getReportData = useCallback(() => {
    userService
      .getAllUser()
      .then((response) => {
        setdataSource(response.data);
      });
  }, [userService]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      render: (_, __) =>
      dataSource.length >= 1 ? (
          <Popconfirm title="Confirmar">
           <Space direction="horizontal">
            <Button type="primary">Cambiar clave</Button>
            <Button type="primary">Cambiar rol</Button>
             </Space>
          </Popconfirm>
        ) : null,
    },
  ];

  return (
    <div className="App">
      <h2>Usuarios</h2>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Space style={{marginBottom: 16}} size="large" direction="horizontal">
          </Space>
        </Row>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
        />
      </Space>
    </div>
  );
}

export default Users;
