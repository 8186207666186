import axios from "axios";
import { MAIN_WS_URL } from "../constants/services";
import TokenService from "./tokenservice";

export default class CompraService {
  constructor() {
    const tokenService = new TokenService();
    this.api = axios.create({
      baseURL: MAIN_WS_URL,
      headers: { Authorization: `Bearer ${tokenService.getCurrentToken()}` },
    });
  }

  getAllFacturas(page, size, dateStart, dateEnd, searchedPrefactura) {
    return this.api.get(
      `/factura?page=${page}&size=${size}&dateStart=${dateStart}&dateEnd=${dateEnd}&prefactura=${searchedPrefactura}`
    );
  }

  downloadFactura(id, type){
    return this.api.get(`/factura/download/${id}/${type}`, {responseType: 'blob'});
  }
}
