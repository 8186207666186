import axios from "axios";
import { MAIN_WS_URL } from "../constants/services";
import TokenService from "./tokenservice";

export default class SmsService {
  constructor() {
    const tokenService = new TokenService();
    this.api = axios.create({
      baseURL: MAIN_WS_URL,
      headers: { Authorization: `Bearer ${tokenService.getCurrentToken()}` },
    });
  }

  getAllSms(page, size, dateStart, dateEnd, searchedTelefono, searchedFactura) {
    return this.api.get(
      `/sms?page=${page}&size=${size}&dateStart=${dateStart}&dateEnd=${dateEnd}&telefono=${searchedTelefono}&factura=${searchedFactura}`
    );
  }

  getAllCompras(page, size, dateStart, dateEnd, searchedOrden) {
    return this.api.get(
      `/compra?page=${page}&size=${size}&dateStart=${dateStart}&dateEnd=${dateEnd}&telefono=${searchedOrden}`
    );
  }

  saveSms(id, sms){
    return this.api.put(`/sms/${id}`, sms);
  }

  saveCompra(id, compra){
    return this.api.put(`/compra/${id}`, compra);
  }

}
