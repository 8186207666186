export const pages = [
  { key: "0", path: "/login", label: "Entrar", admin: true, showInMenu: false },
  { key: "1", path: "/", label: "Inicio", admin: false, showInMenu: true },
  {
    key: "2",
    path: "/reporte-ventas",
    label: "Reporte SMS",
    admin: false,
    showInMenu: true,
  },
  {
    key: "3",
    path: "/reporte-compras",
    label: "Reporte compras",
    admin: false,
    showInMenu: true,
  },
  {
    key: "4",
    path: "/reporte-facturas",
    label: "Reporte facturas",
    admin: false,
    showInMenu: true,
  },
  {
    key: "5",
    path: "/users",
    label: "Usuarios",
    admin: true,
    showInMenu: true,
  },
  {
    key: "6",
    path: "/update-profile",
    label: "Actualizar perfil",
    admin: false,
    showInMenu: true,
  },
  {
    key: "7",
    path: "/forgot-password",
    label: "Olvidé mi clave",
    admin: false,
    showInMenu: false,
  },
  {
    key: "8",
    path: "/signup",
    label: "Registrarse",
    admin: false,
    showInMenu: false,
  },
];
