import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, Redirect, useHistory } from "react-router-dom";
import TokenService from "../services/tokenservice";
import { Card } from 'antd';

export default function Signup() {
  const tokenService = new TokenService();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Las claves no coinciden");
    }

    try {
      setError("");
      setLoading(true);
      signup(emailRef.current.value, passwordRef.current.value).then(_ => {
        history.push("/");
      }).catch(e =>{
        console.log("errorsignup", e);
      });
      
    } catch (e) {
      console.log(e);
      setError("Error al registrarte");
    }finally{
      setLoading(false);
    }
  }

  return tokenService.getCurrentToken() ? (
    <Redirect to="/" />
  ) : (
    <>
      <div className="w-100" style={{ maxWidth: "400px", margin: "auto" }}>
        <Card>
            <h2 className="text-center mb-4">Registrarse</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Correo</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Clave</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>Confirmación de clave</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                />
              </Form.Group>
              <br />
              <Button disabled={loading} className="w-100" type="submit">
                Registrarse
              </Button>
            </Form>
        </Card>
        <div className="w-100 text-center mt-2">
          ¿Ya tienes una cuenta? <Link to="/login">Entrar</Link>
        </div>
      </div>
    </>
  );
}
