import { Space, DatePicker, Button, Typography } from "antd";

function FilterControls(props) {
  const { Text } = Typography;
  const { onChangeDate, getCallback, showButton = false } = props;
  return (
    <Space direction="horizontal">
      <Text>Fecha entre </Text>
      <DatePicker onChange={(_, dateStr) => onChangeDate(dateStr, "start")} />
      <Text> y </Text>
      <DatePicker onChange={(_, dateStr) => onChangeDate(dateStr, "end")} />
      {showButton && (
        <Button onClick={getCallback} type="primary">
          Obtener
        </Button>
      )}
    </Space>
  );
}

export default FilterControls;
