import { Space, Typography } from "antd";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";

function UserInfo() {
  const { currentUser } = useAuth();
  const { Text } = Typography;

  return (
    (currentUser && (
      <Space direction="vertical">
        <strong>Usuario:</strong>
        <Link to="/update-profile">
          <Text code>{currentUser && currentUser.email}</Text>
        </Link>
      </Space>
    )) ?? null
  );
}

export default UserInfo;
