import axios from "axios";
import { MAIN_WS_URL } from "../constants/services";
import TokenService from "./tokenservice";

export default class UserService {
  constructor() {
    const tokenService = new TokenService();
    this.api = axios.create({
      baseURL: MAIN_WS_URL,
      headers: { Authorization: `Bearer ${tokenService.getCurrentToken()}` },
    });
  }
  getAllUser() {
    return this.api.get(
      `/user`
    );
  }

}
