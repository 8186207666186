// React
import React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";

// Antd
import { Table, Space, notification, Button, Input, Row, Col, Layout} from "antd";

import { SearchOutlined } from "@ant-design/icons";

// Components
import FilterControls from "./FilterControls";

// Plugins
import Highlighter from "react-highlight-words";

// Dates
import moment from "moment";

// Services
import FacturasService from "../services/facturasservice";

function ReporteFacturas() {
  const facturasService = useMemo(() => new FacturasService(), []);
  const [dataSource, setdataSource] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [filterText, setFilterText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [originalDatasource, setOriginalDatasource] = useState(0);

  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [matches, setMatches] = useState(window.matchMedia("(min-width: 1868px)").matches);

  const getReportData = useCallback(() => {
    facturasService
      .getAllFacturas(page, size, dateStart, dateEnd, filterText)
      .then((response) => {
        const { content, totalElements } = response.data;
        setTotalRows(totalElements);
        setdataSource(content);
        setOriginalDatasource(content);
      });
  }, [facturasService, filterText, page, size, dateStart, dateEnd]);

  useEffect(() => {
    getReportData();
    const handler = e => {
    
      setMatches(e.matches);
    }
    window.matchMedia("(min-width: 1868px)").addEventListener('change', handler);
  }, [getReportData]);

  let searchInput = undefined;

  const onChangePage = (p, s) => {
    setSize(s);
    setPage(p - 1);
  };

  const onChangeDate = (dateStr, dp) => {
    if (dp === "start") {
      setDateStart(dateStr);
    } else {
      setDateEnd(dateStr);
    }
  };

  const getCallback = () => {
    if (dateStart.length === 0 || dateEnd.length === 0) {
      notification["error"]({
        message: "Error",
        description: "Las fechas no son correctas",
      });
      return;
    }
    getReportData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              if(dataIndex==='prefactura'){
                setFilterText(selectedKeys[0]);
              }
              setSearchColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText, filterText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setdataSource(originalDatasource);
    setSearchText("");
    setFilterText("");
  };

  const handleDownload = (response) => {
    console.log(response);
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = temp;
    link.setAttribute('download', response.headers["x-suggested-filename"]); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      responsive: ['xl'],
    },
    {
      title: "Fecha",
      dataIndex: "createdat",
      key: "createdat",
      sorter: (a, b) => moment(a.createdat).unix() - moment(b.createdat).unix(),
      sortDirections: ["descend", "ascend"],
      width: '10%'
    },
    {
        title: "Factura",
        dataIndex: "factura",
        key: "factura",
    },
    {
        title: "ID Cliente",
        dataIndex: "clienteid",
        key: "clienteid",
        responsive: ['sm'],
      },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
      responsive: ['xl'],
    },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
      responsive: ['xl'],
    },
    {
      title: "Importe en pesos",
      dataIndex: "importepesos",
      key: "importepesos",
      render: (_, e) =>{
        return (<span>{e.importepesos.toLocaleString()}</span>)
      }
    },
    {
        title: "Importe en USD",
        dataIndex: "importedolares",
        key: "importedolares",
        render: (_, e) =>{
          return (<span>{e.importedolares.toLocaleString()}</span>)
        }
    },
    {
        title: "TC",
        dataIndex: "tipodecambio",
        key: "tipodecambio",
        responsive: ['xl'],
    },
    {
      title: "Prefactura",
      dataIndex: "prefactura",
      key: "prefactura",
      sorter: (a, b) => a.prefactura>b.prefactura,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("prefactura"),
    },
    {
        title: 'Descargar',
        dataIndex: 'operation',
        render: (_, e) =>{
            return (
                dataSource.length >= 1 ? (
                    <Space direction={matches ? "horizontal" : "vertical"}>
                    
              <Button type="primary" onClick={()=>{facturasService.downloadFactura(e.id,"pdf").then(response=>handleDownload(response))}}>Descargar PDF</Button>
              <Button type="default" onClick={()=>{facturasService.downloadFactura(e.id,"xml").then(response=>handleDownload(response))}}>Descargar XML</Button>
            </Space>
          ) : null)
        }
      },
  ];

  return (
    <div style={{display: "flex"}}>
      <Space direction="vertical" style={{flex: 1}} >
        <h2>Reporte de facturas</h2>
          <Space style={{marginBottom: 16}} size="large" direction="horizontal">
            <FilterControls onChangeDate={onChangeDate} getCallback={getCallback} />
          </Space>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: size,
            onChange: onChangePage,
            total: totalRows,
          }}
          style={{width: "auto"}}
          />
      </Space>
    </div>
      
  );
}

export default ReporteFacturas;
