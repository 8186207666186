import { Link, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";

import { pages } from "../constants/menu";

function BreadcrumBar(props) {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    try {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const { key, path, label } = pages.find((e) => e.path === url);
      return (
        <Breadcrumb.Item key={key}>
          <Link to={path}>{label}</Link>
        </Breadcrumb.Item>
      );
    } catch {
      return null;
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Inicio</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>{breadcrumbItems}</Breadcrumb>
  );
}

export default withRouter(BreadcrumBar);
